@import "../master";

.parent {
    display: flex;
    justify-content: center;
}

.full-width {
    width: 100vw;
    height: 100vh;
}

.center-v {
    display: flex;
    align-items: center;
}

.center-h {
    display: flex;
    justify-content: center;
}

.f-v {
    flex-direction: column;
}

.parent-center {
    max-width: 350px;
    width: 100%;
    padding: 2em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.max-width {
    width: 100%;
}

.space-around-v {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.login-input {
    border: 2px solid $primary-color;
    border-radius: 0.5em;
    padding: 0.5em 1em;
    box-sizing: border-box;
    font-size: large;
    font-weight: 300;
    font-family: $common-font;
}

.login-input::placeholder {
    font-weight: 300;
    color: rgba($primary-color, .5);
}

.login-icon {
    max-width: 250px;
}

.button {

    background-color: $primary-color;
    color: white;
    text-align: center;
    font-family: $common-font;
    padding: 1em;
    box-sizing: border-box;
    border-radius: 0.5em;
    cursor: pointer;
}

.button-icon {
    cursor: pointer;
    font-family: $common-font;
    box-sizing: border-box;
}

.m-t-l {
    margin-top: 2em;
}

.m-t-m {
    margin-top: 1em;
}

.m-t-l {
    margin-top: 2em;
}

.m-b-l {
    margin-bottom: 2em;
}

.dashboard-parent {
    max-width: $max-width;
    width: 100%;
    padding: 1em 1em 0;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    position: fixed;
    background-color: white;
    z-index: 10;
}

.dashboard-content {
    padding-top: 5em;
    width: 100%;
    max-width: $max-width;
    top: 64px;
    z-index: 5;
    padding-bottom: 128px;
}

.dashboard-top-bar {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.5em;
    border-bottom: 2px solid $primary-color;
    box-sizing: border-box;
}

.dashboard-label {
    font-family: $common-font;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    display: flex;
    align-items: center;
    color: $primary-color;
}

.dashboard-filter {
    max-width: $max-width;
    box-sizing: border-box;
    width: 100%;
    padding: 0 1em;
    display: grid;
    grid-gap: 0.5em;
    grid-template-columns: 1fr 1fr;
}

.filter-parent {
    border: 2px solid $primary-color-light;
    border-radius: 0.5em;
    padding: 0.5em 1em;
    display: flex;

    &.pdf-editor{
        padding-top: 0em;
        background: white;
        
    }
}

.filter-input {
    border: 0;
    box-sizing: border-box;
    font-size: large;
    font-weight: 300;
    width: 100%;
    font-family: $common-font;
    display: inline-flex;
    min-width: 128px;
}

.filter-input:focus {
    border: 0;
    outline: none;
}

.new-section {
    max-width: $max-width;
    width: 100%;
    padding: 1em;
    box-sizing: border-box;
}

.mini-title {
    color: $primary-color;
    font-size: 14px;
    font-weight: 700;
}

.e-paper-list {
    max-width: $max-width;
    box-sizing: border-box;
    width: 100%;
    display: grid;
    grid-gap: 0.5em;
    grid-template-columns: repeat(2, 1fr);
}

.navigation-bar-parent {
    position: fixed;
    bottom: 16px;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 10;
}

.navigation-bar {
    background-color: white;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
    border-radius: 32px;
    padding: 4px 6px;
    display: flex;
    gap: 4px;
    cursor:pointer;
}

@media screen and (max-width: $max-width) {
    .e-paper-list {
        grid-template-columns: 1fr;
    }

}

.title-input {
    font-family: $common-font;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: $primary-color;
    margin-bottom: 0.5em;
}

.add-page-datetime {
    max-width: $max-width;
    box-sizing: border-box;
    width: 100%;
    display: grid;
    grid-gap: 0.5em;
    grid-template-columns: 1fr 1fr;

}

.add-paper-parent {
    max-width: $max-width;
    width: 100%;
    padding: 1em 1em 0;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

}

.tag-button{
    align-items: center;
    appearance: none;
    background: white;
    border: none;
    border-radius: 50%;
    color: $primary-color;
    cursor: pointer;
    display: inline-flex;
    font-size: 12px;
    height: 15px;
    justify-content: center;
    line-height: 0;
    margin-left: 8px;
    padding: 0;
    transform: rotate(45deg);
    width: 15px;
}

.ePaperPage-filter {
    max-width: $max-width;
    box-sizing: border-box;
    width: 100%;
    padding: 0 1em;
}

.add-page-filter {
    max-width: $max-width;
    box-sizing: border-box;
    width: 100%;
    padding: 0 1em;
}

.date-filter-parent {
    max-width: $max-width;
    box-sizing: border-box;
    width: 100%;
    display: grid;
    grid-gap: 0.5em;
    grid-template-columns: 1fr 1fr;
}

@media screen and (max-width: 700px) {
    .dashboard-filter {
        grid-template-columns: 100%;
    }

    .date-filter-parent{
        grid-template-columns: 100%;
    }

    .add-page-datetime {
        grid-template-columns: 100%;
    }

        
}

.daily-readings-parent {
    box-sizing: border-box;
    width: 100%;
    padding-Top: 16px;
    padding-Bottom: 16px;
    background: linear-gradient(0deg, #FFFFFF, #FFFFFF),
        rgba(255, 255, 255, 0.25);
    border: 1px solid rgba(255, 67, 73, 0.5);
    box-shadow: 0px 0px 16px rgba(255, 255, 255, 0.25);
    border-radius: 16px;
}

.daily-reading-viewCount {
    margin-left: auto;
}
tr:nth-child(even) {
    background-color:rgba(255, 67, 73, 0.125);
}

.daily-reading-table {
    border-spacing: 0;
    border-collapse: collapse;
    font-family: $common-font;
    font-size: 16px;
}


td{
   padding-left:1.3em;
   padding-right:1.3em;
   padding-top:0.5em;
   padding-bottom:0.5em;
}

.daily-readings-title {
    font-family: $common-font;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.75);
    margin:1em;
    font-size:18px
}

.daily-readings-header{
    font-family: $common-font;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);
    padding-top: 0.5em;
    white-space: nowrap;
}

.logout-button {
    border: 2px solid #EE4139;
    border-radius: 10px;
    font-family: $common-font;
    color: $primary-color;
    background: white;
    text-align: center;
    padding: 0.5em;
    width: 120px;
    box-sizing: border-box;
    font-weight: bolder;
    font-size: medium;
    cursor: pointer;
}


.settings-filter{
    max-width: $max-width;
    box-sizing: border-box;
    width: 100%;
    padding: 0 1em;
    display: grid;
    grid-gap: 0.5em;
    grid-template-columns: 100%;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.autosave-title{
    font-family: $common-font;
    color:rgba(255, 67, 73, 0.75);
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
}

.button-desktop{
    border-radius: 50%;
    padding: 2px;
}

.button-desktop:hover{
    background-color: #EE413920;
}

.topbar-button{
    padding: 8px;
    height: 24px;
    border-radius: 25%;
}
.topbar-button:hover{

    background-color: #EE413920;
}