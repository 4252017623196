@import "../master";

.message-dialog-parent{
    font-family: $common-font;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00000080;
    backdrop-filter: blur(1px);
    z-index: 100;
}

.message-dialog-frame{
    background-color: white;
    border-radius: 1em;
    padding: 1.5em;
    gap: 2em;
    display: flex;
    flex-direction: column;
    box-shadow: 6px 6px 6px #00000040;
}

.message-dialog-title{
    font-weight: 700;
    font-size: x-large;
}

// .message-dialog-message{
// }

.message-dialog-button-parent{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1em;
}

.message-dialog-button-ok{
    cursor: pointer;
    background-color: $primary-color;
    border-radius: 0.5em;
    padding: 0.5em 1em;
    color: white;
    font-weight: 700;
    box-shadow: 0px 4px 4px $primary-color-light;
}

.message-dialog-button-ok:hover{
    background-color: $primary-color-light;
}

.message-dialog-button-cancel{
    cursor: pointer;
    background-color: gray;
    border-radius: 0.5em;
    padding: 0.5em 1em;
    color: white;
    font-weight: 700;
    box-shadow: 0px 4px 4px #00000040;
}

.message-dialog-button-cancel:hover{
    background-color: #00000040;
}