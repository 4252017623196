@import url( 'https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@400;500;700&display=swap');

$primary-color: #FF4349;
$primary-color-light: rgba($primary-color, 0.5);
$primary-color-faint: rgba($primary-color, 0.25);
$common-font: "Noto Sans SC";
$max-width: 900px;
$common-font-size: 14px;
$title-font-size: $common-font-size*1.5;
$header-font-size: $common-font-size*2;

body {
    overscroll-behavior-y: none;
}

.p-a-s {
    padding: 0.5em;
}

.p-a-m {
    padding: 1em;
}

.p-a-l {
    padding: 2em;
}

.m-a-s {
    margin: 0.5em;
}

.m-a-m {
    margin: 1em;
}

.m-a-l {
    margin: 2em;
}

.full-width {
    width: 100%;
}

.no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
