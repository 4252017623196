@import "../master";

.bracket {
    background: $primary-color-faint;
    border: 2px dashed $primary-color-light;
    font-family: $common-font;
    box-sizing: border-box;
    border-radius: 4px;
    position: absolute;
    pointer-events: none;
}

.delete-highlight-button {
    position: absolute;
    width: 24px;
    height: 24px;
    object-fit: fill;
    right: 0;
    top: 0;
    transform: translate(12px, -12px);
    pointer-events: visible;
    cursor: pointer;
    z-index: 10;
}

.bracket-input {
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    font-family: 'Noto Sans SC';
    pointer-events: visible;
    background-color: #00000080;
    color: white;
    font-size: medium;
    border: 0;
    border-radius: 4px;
    font-weight: 700;
    resize: none;
    backdrop-filter: blur(1px);
}
.bracket-input:focus {
    background-color: #000000B0;
    backdrop-filter: blur(2px);
}

.type-parent {
    display: flex;
    border-radius: 1em 1em 0 0;
    background-color: white;
    position: absolute;
    padding: 8px;
    top: 0;
    left: 0;
    height: 24px;
    transform: translate(0px, -44px);
    border: 2px solid $primary-color-light;
    cursor: pointer;
    gap: 8px;
    pointer-events: visible;
    align-items: center;
    justify-content: center;
}

.type-button-selected {
    padding: 8px;
    font-weight: 700;
    font-size: medium;
    color: $primary-color;
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
}
.type-button-not-selected {
    padding: 8px;
    font-weight: 300;
    font-size: small;
    color: $primary-color-light;
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
}
