@import "../master";

.controls {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}



.page-arranger-page {
    border: 1px solid rgba(255, 67, 73, 0.25);
    border-radius: 4px;
    width: 80px;
    height: 118px;
}


.page-arranger-parent {
    position: relative;
    padding: 8px;

    .page-number {
        display: flex;
        justify-content: center;
        font-weight: 700;
        font-size: 0.9rem;
        display: flex;
        color: #000000;
    }
}

.btn {
    cursor: pointer;

    img {
        width: 1.25rem;
        filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
    }

    &.delete-button {
        height: 100%;
    }

    &.add-button {

        input {
            width: 0;
        }

        input::-webkit-file-upload-button {
            display: none;
        }
    }

    &.reupload-button {
        input {
            width: 0;
        }

        input::-webkit-file-upload-button {
            display: none;
        }
    }
}