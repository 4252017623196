@import "../master";

.e-paper-card-parent {
    height: 150px;
    border:1px solid rgba(255, 67, 73, 0.5);
    border-radius: 8px;
    display: flex;

}

.e-paper-thumbnail {
    height: 100%;
    object-fit: cover;
    border-radius: 6px 0 0 6px;
}

.e-paper-title {
    font-family: $common-font;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.e-paper-date {
    opacity: 0.5;
    font-size: 12px;
    margin-top: 8px;
    font-weight: 600;
}

.e-paper-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.e-paper-bottom-row {
    display: flex;
    justify-content: flex-end;
    box-sizing: border-box;
    gap: 0.5em;
}

.e-paper-tags-parent {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    flex-wrap: wrap;
    gap: 0.25em;
    width: -webkit-fill-available;
    align-items: flex-end;
    grid-gap: 0.5em;
}

.edit-button {
    background: $primary-color;
    box-shadow: 0 4px 4px $primary-color-light;
    border-radius: 4px;
    min-width: 100px;
    color: white;
    font-family: $common-font;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: min-content;
}

.e-paper-viewCount{
    font-family: $common-font;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.75);
}